import React from 'react';
import { useTranslation } from 'react-i18next';

import { usePartnerImages } from '@widgets/our-partners/hooks/usePartnerImages';
import { PartnerItem } from '@widgets/our-partners/partner';

import { QAnswerBackButton } from '@widgets/questions-answers/specific/QAnswerBackButton';
import { QASpecificOtherQuestions } from '@widgets/questions-answers/specific/QASpecificOtherQuestions';
import { QASpecificProvider } from '@widgets/questions-answers/specific/QASpecificProvider';

import shops from '@values/shops';

interface QAOurPartnersPageProps {
  location: any;
}

const QAOurPartnersPage: React.FC<QAOurPartnersPageProps> = ({ location }) => {
  const { t } = useTranslation();
  const question = 'question-category-online-help-2';

  const shopsData = usePartnerImages(shops);

  return (
    <QASpecificProvider question={question}>
      <div className="pt-6 pb-12 lg:pt-12 xl:pb-52">
        <div className="container_xl">
          <QAnswerBackButton location={location} />
          <h1 className="mt-4 text-2xl lg:mt-6 lg:text-4xl">{t(question)}</h1>

          <div className="mt-8 lg:mt-16 lg:flex lg:gap-x-8 items-start">
            <div>
              <p className="mb-10">{t('answer-category-online-help-2')}</p>

              <div className="flex mb-8 flex-wrap items-center justify-center gap-x-8 gap-y-4">
                {shopsData.map(({ name, image, url }) => (
                  <PartnerItem key={name} name={name} url={url} image={image} innerRef={null} />
                ))}
              </div>
            </div>

            <QASpecificOtherQuestions />
          </div>
        </div>
      </div>
    </QASpecificProvider>
  );
};

export default QAOurPartnersPage;
